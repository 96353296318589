<template>
  <Modal
    :visible="value"
    width="848px"
    class="case-img-dialog"
    :title="caseTitle"
    :confirm-loading="loading"
    ok-text="保存"
    cancel-text="取消"
    destroy-on-close
    @ok="handleOk"
    @cancel="handleCancel"
  >

    <p class="upload-des">支持jpg、jpeg、png图片格式上传</p>

    <el-button class="clear-case" type="warning" size="small" @click="clearCase">清 空</el-button>

    <div class="upload-btn" @click="isShowMedias = true">
      <i class="el-icon-plus" />
    </div>

    <draggable v-model="allImg">
      <div class="img-item" v-for="(item, index) in allImg" :key="index" @click="openImg(index)">
        <span v-if="item.mediaId === coverId" class="cover-tags">
          <span class="cover-tag" />
          <span class="cover-tag-text">封面</span>
        </span>
        <span v-else class="cover-tags setting-cover" @click.stop="coverId = item.mediaId">
          <span class="cover-tag" />
          <span class="cover-tag-text">设为封面</span>
        </span>
        <img :src="item.mediaUrl" alt="">
        <el-button class="close-icon" type="danger" icon="el-icon-delete" circle @click.stop="delImg(index)" />
      </div>
    </draggable>

    <!--媒体库-->
    <Medias :mediasShow.sync="isShowMedias" :dataFiles.sync="dataFiles" />

    <ImageView v-if="isShowImageView" :on-close="closeViewer" :url-list="previewImgList" />

  </Modal>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { activityContentMediaEdit } from '@/api/businessCard'
import { Modal } from 'ant-design-vue'
import draggable from 'vuedraggable'
export default {
  name: 'caseImgDialog',
  components: {
    Modal,
    draggable,
    ElImageViewer,
    'ImageView': ElImageViewer
  },
  props: {
    value: { type: Boolean, default: false },
    caseTitle: { type: String, default: '' },
    info: { type: Object, default: () => {} }
  },
  data() {
    return {
      loading: false,
      isShowMedias: false,
      dataFiles: [],
      allImg: [],
      coverId: '',
      isShowImageView: false,
      previewImgList: []
    }
  },
  watch: {
    'value': function () {
      if (this.value) {
        this.coverId = this.info.coverId
        this.allImg = JSON.parse(JSON.stringify(this.info.mediaList))
      }
    },
    'dataFiles': function() {
      this.update()
    }
  },
  methods: {
    closeViewer() {
      this.isShowImageView = false
    },
    update() {
      if (this.dataFiles && this.dataFiles.length) {
        const dataFiles = this.dataFiles.map(v => {
          return {
            mediaId: v.id,
            mediaUrl: v.url
          }
        })
        this.allImg = [...this.allImg, ...dataFiles]
      }
    },
    delImg(index) {
      this.allImg.splice(index, 1)
    },
    handleOk() {
      activityContentMediaEdit({
        contentId: this.info.contentId,
        mediaIdList: this.allImg.length ? this.allImg.map(v => v.mediaId) : [],
        coverId: this.coverId,
      }).then(() => {
        this.$emit('success')
        this.$message.success('编辑成功')
        this.handleCancel()
      })
    },
    openImg(index) {
      const srcList = this.allImg.map(v => v.mediaUrl)
      const startImg = srcList.splice(index, srcList.length)
      const endImg = srcList.splice(0, index)
      this.previewImgList = [...startImg, ...endImg]
      this.isShowImageView = true
    },
    handleCancel() {
      this.$emit('input', false)
    },
    clearCase() {
      this.$confirm('是否确认清空当前系列下所有案例', '清空案例', {
        type: 'warning',
        distinguishCancelAndClose: true,
        confirmButtonText: '确 认',
        cancelButtonText: '取 消'
      }).then(action => {
        if (action === 'confirm') {
          this.allImg = []
        }
      }).catch(() => {
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.case-img-dialog{
  .img-item{
    display: inline-block;
    width: 148px;
    height: 148px;
    box-shadow: $cardShadow;
    margin: 0 12px 12px 0;
    cursor: pointer;
    position: relative;
    .cover-tags{
      position: absolute;
      left: 0;
      top: 0;
      .cover-tag{
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        border-top: 36px solid #67C23A;
        border-right: 36px solid transparent;
        letter-spacing: 2px;
      }
      .cover-tag-text{
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        font-size: 10px;
        display: block;
        width: 32px;
        font-weight: bold;
        color: #fff;
        transform: rotate(312deg) translate(-2px, -2px) scale(.8);
      }
    }
    .setting-cover{
      display: none;
      .cover-tag{
        border-top: 48px solid #909399;
        border-right: 48px solid transparent;
      }
      .cover-tag-text{
        width: 64px;
        transform: rotate(313deg) translate(-10px, -6px) scale(.8);
      }
    }
    .close-icon{
      position: absolute;
      right: -6px;
      top: -6px;
      font-size: 12px;
      padding: 4px;
      display: none;
    }
    img{
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &:hover{
      box-shadow: 0 0 7px 0 rgb(24,144,255);
      .close-icon{
        display: block;
      }
      .setting-cover{
        display: block;
      }
    }
  }
  .upload-btn{
    display: inline-block;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
    box-shadow: $cardShadow;
    border-radius: 4px;
    font-size: 40px;
    font-weight: normal;
    color: $title1;
    cursor: pointer;
    margin: 0 12px 12px 0;
  }
  .draggable-img{
    display: flex;
  }
}
.upload-des{
  font-size: 14px;
  color: $title1;
  height: 24px;
  line-height: 24px;
  margin-bottom: 24px;
}
  .clear-case{
    position: absolute;
    right: 24px;
    top: 74px;
  }
</style>
